import React from 'react';
import './App.css';
import Navigation from "./Components/Navigation";
import Header from "./Components/Header";
import Download from "./Components/Download";
import Features from "./Components/Features";
import Cta from "./Components/Cta";
import SocialMedia from "./Components/SocialMedia";
import Footer from "./Components/Footer";
import "./styles/scss/new-age.scss";
import {HashRouter, Route, Switch,} from "react-router-dom";
import Privacy from "./Components/Privacy";
import Legal from "./Components/Legal";
import Komoot from "./Components/Komoot";
import ForgotPassword from "./Components/ForgotPassword";
import CookieBanner from "./Components/CookieBanner";
import Terms from "./Components/Terms";
import AppPrivacy from "./Components/AppPrivacy";
import Welcome from "./Components/Welcome";
import NavigationNoMenu from "./Components/NavigationNoMenu";
import ResendConfirmation from "./Components/ResendConfirmation";
import Contact from "./Components/Contact";
import Redirect from "./Components/Redirect";
import Promotion from "./Components/Promotion";


function App() {
    return (
        <React.StrictMode>
            <HashRouter basename={process.env.PUBLIC_URL}>
                <Switch/>
                <Route exact path={"/"} component={() => {
                    return (<div>
                        <Navigation/>
                        <Header/>
                        <Download/>
                        <Features/>
                        <Cta/>
                        <SocialMedia/>

                    </div>)
                }}/>
                <Route exact path={"/privacy"} component={() => {
                    return (<div>
                        <NavigationNoMenu/>
                        <Privacy/>
                    </div>)
                }}/>

                <Route exact path={"/legal"} component={() => {
                    return (<div>
                        <NavigationNoMenu/>
                        <Legal/>
                    </div>)
                }}/>

                <Route exact path={"/komoot"} component={() => {
                    return (<div>
                        <NavigationNoMenu/>
                        <Komoot/>
                    </div>)
                }}/>
                <Route exact path={"/forgot-password"} component={() => {
                    return (<div>
                        <NavigationNoMenu/>
                        <ForgotPassword/>
                    </div>)
                }}/>

                <Route exact path={"/terms"} component={() => {
                    return (<div>
                        <NavigationNoMenu/>
                        <Terms/>
                    </div>)
                }}/>

                <Route exact path={"/app-privacy"} component={() => {
                    return (<div>
                        <NavigationNoMenu/>
                        <AppPrivacy/>
                    </div>)
                }}/>

                <Route exact path={"/welcome"} component={() => {
                    return (<div>
                        <NavigationNoMenu/>
                        <Welcome/>
                    </div>)
                }}/>


                <Route exact path={"/resend-confirmation"} component={() => {
                    return (<div>
                        <NavigationNoMenu/>
                        <ResendConfirmation/>
                    </div>)
                }}/>

                <Route exact path={"/contact"} component={() => {
                    return (<div>
                        <NavigationNoMenu/>
                        <Contact/>
                    </div>)
                }}/>

                <Route exact path={"/share"} component={() => {
                    return (<div>
                        <NavigationNoMenu/>
                        <Redirect/>
                    </div>)
                }}/>

                <Route exact path={"/redirect"} component={() => {
                    return (<div>
                        <NavigationNoMenu/>
                        <Promotion/>
                    </div>)
                }}/>
                <Switch/>
                <Footer/>
                <CookieBanner/>
            </HashRouter>
        </React.StrictMode>
    );
}

export default App;
